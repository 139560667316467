.capstone-logo-container {
    display: flex;
    justify-content: left;
    padding-top: 25px;
}

.capstone-logo {
    display: flex;
    position: fixed;
    border: 1px solid white;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    top: 4vh;
}

.capstone-subtitle {
    width: 100% !important;
}

.capstone-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 120px;
}

/* New Media Styles */
.media-container {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.video-wrapper {
    display: flex;
    justify-content: center;
}

.youtube-embed-container {
    max-width: 100%;
    overflow: hidden;
    /* Optional: Responsive scaling 
       Adjust width/height ratio if desired
    */
    position: relative;
    padding-bottom: 56.25%; /* 16:9 ratio */
    height: 0;
}

.youtube-embed-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.pdf-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
}

.capstone-pdf {
    width: 100%;
    height: 600px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.pdf-download-link a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
}

.pdf-download-link a:hover {
    text-decoration: underline;
}

.socials-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.contact-socials {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
