@import "../../../data/styles.css";
.social,
.email-wrapper {
	display: flex;
	padding-bottom: 20px;
}

.social a,
.email-wrapper a {
	text-decoration: none;
}

.social-icon {
	font-size: 15px;
	color: var(--secondary-color);
}

.social-text {
	color: var(--secondary-color);
	padding-left: 30px;
	margin-top: -20px;
	font-weight: 600;
	font-size: 14px;
}

.email::before {
	content: "";
	display: block;
	padding-top: 20px;
	border-top: 3px solid #f4f4f5;
	margin-top: 20px;
}

.email-wrapper {
	padding-top: 20px;
}

.button-rounded {
	color: var(--secondary-color);
    background-color: white; /* White background */
    border: none; /* Removes the default border */
    padding-top: 10px; /* Top and bottom padding 10px, left and right padding 20px */
	padding-bottom: 10px;
    text-decoration: none; /* Remove underline from text */
    display: inline-block; /* Allows setting padding and margins */
    font-size: 14px; /* Set the font size */
    border-radius: 3px; /* Rounded corners with a radius of 15px */
    cursor: pointer; /* Changes the mouse pointer on hover */
	font-weight: 600;
	padding-left: 15px;
}
